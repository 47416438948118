import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import { Body } from 'TMComponents/Typography';
import { breakpoints } from 'TMConstants';

const Text = styled(Body)`
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    margin: 0;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const ArticleDate = ({ date }) => (
    <Text>
        <b>{date}</b>
    </Text>
);

ArticleDate.propTypes = {
    date: string.isRequired
};

export default ArticleDate;
