import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Email = () => {
    const { colorSystem } = useContext(ThemeContext);

    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Icon_email_white">
                <g id="Group Copy 3">
                    <g id="Oval" filter="url(#filter0_dd)">
                        <circle
                            cx="32"
                            cy="32"
                            r="20"
                            fill={colorSystem.primary[1]}
                        />
                    </g>
                </g>
                <g id="Icon/Inboxwhite">
                    <g id="Group 11">
                        <g id="Group">
                            <path
                                id="Fill 1"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M31.8505 33.6906C31.8815 33.7156 31.9174 33.7287 31.9551 33.7287C31.9927 33.7287 32.0287 33.7156 32.0597 33.6906L42.0474 25.3525C42.0834 25.3224 42.1047 25.2783 42.1064 25.2299C42.1077 25.1815 42.0884 25.1353 42.0541 25.1022C41.7513 24.8222 41.3589 24.6665 40.9506 24.6665H22.9595C22.5513 24.6665 22.1589 24.8222 21.8561 25.1022C21.8218 25.1353 21.8025 25.1815 21.8038 25.2299C21.8054 25.2783 21.8272 25.3224 21.8627 25.3525L31.8505 33.6906Z"
                                fill={colorSystem.primary[3]}
                            />
                            <g id="Group 5">
                                <mask
                                    id="mask0"
                                    mask-type="alpha"
                                    maskUnits="userSpaceOnUse"
                                    x="21"
                                    y="25"
                                    width="9"
                                    height="13">
                                    <path
                                        id="Clip 4"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21.334 25.4888H29.7778V37.7324H21.334V25.4888Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask0)">
                                    <path
                                        id="Fill 3"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M21.6561 25.6084C21.6151 25.5749 21.5829 25.5329 21.5557 25.4888C21.413 25.7386 21.334 26.0237 21.334 26.3173V36.8309C21.334 37.1563 21.4294 37.4668 21.6004 37.7324C21.6398 37.6611 21.695 37.5996 21.7648 37.5551L29.7782 32.3886L21.6561 25.6084Z"
                                        fill={colorSystem.primary[3]}
                                    />
                                </g>
                            </g>
                            <g id="Group 8">
                                <mask
                                    id="mask1"
                                    mask-type="alpha"
                                    maskUnits="userSpaceOnUse"
                                    x="34"
                                    y="25"
                                    width="9"
                                    height="13">
                                    <path
                                        id="Clip 7"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M34.1328 25.4883H42.5768V37.7323H34.1328V25.4883Z"
                                        fill="white"
                                    />
                                </mask>
                                <g mask="url(#mask1)">
                                    <path
                                        id="Fill 6"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M42.577 26.3173C42.577 26.0241 42.4984 25.7382 42.3558 25.4883C42.3277 25.5328 42.2951 25.5748 42.2545 25.6088L34.1328 32.389L42.1479 37.5554C42.2169 37.5996 42.2717 37.6611 42.3106 37.7323C42.4817 37.4668 42.577 37.1562 42.577 36.8308V26.3173Z"
                                        fill={colorSystem.primary[3]}
                                    />
                                </g>
                            </g>
                            <path
                                id="Fill 9"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M33.868 32.6104L32.2684 33.9459C32.1743 34.0218 32.0676 34.06 31.956 34.06C31.8443 34.06 31.738 34.0222 31.6485 33.9505L30.0435 32.6104L21.9385 37.8349C21.8946 37.8633 21.8674 37.9113 21.8636 37.9643C21.8603 38.0156 21.8816 38.067 21.9222 38.1017C22.2137 38.347 22.5814 38.4814 22.9604 38.4814H40.9515C41.3305 38.4814 41.6982 38.347 41.9889 38.1017C42.0299 38.067 42.0512 38.0156 42.0483 37.9643C42.045 37.9113 42.0165 37.8633 41.973 37.8349L33.868 32.6104Z"
                                fill={colorSystem.primary[3]}
                            />
                        </g>
                    </g>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_dd"
                    x="0"
                    y="0"
                    width="60"
                    height="60"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.898039 0 0 0 0 0.968627 0 0 0 0 1 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow"
                        result="effect2_dropShadow"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default Email;
