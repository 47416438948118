import React from 'react';
import styled from 'styled-components';
import { bool, func } from 'prop-types';

import categoryProps from 'TMPropTypes/categoryProps';
import Add from 'TMComponents/Icons/Add';
import Remove from 'TMComponents/Icons/Remove';
import FlexWrapper from 'TMComponents/FlexWrapper';
import { CategoryLabels, LargeText } from 'TMComponents/Typography';

const Wrapper = styled.div`
    ${CategoryLabels}
    align-items: center;
    background: ${({ isActive, theme }) =>
        isActive
            ? theme.colorSystem.primary[4]
            : theme.colorSystem.primary[3]};
    color: ${({ isActive, theme }) =>
        isActive
            ? theme.colorSystem.primary[3]
            : theme.colorSystem.primary[4]};
    border-radius: 10px;
    box-shadow: ${({ theme }) => theme.colorSystem.shadows['8']};
    display: inline-flex;
    justify-content: center;
    margin-right: 10px;
    padding: 0 32px;

    ${({ large, allowToggle }) => (large || allowToggle) && LargeText};
    ${({ large, allowToggle }) =>
        (large || allowToggle) &&
        `
        padding: 16px 24px;
        border-radius: 31px;
        height: 32px;
    `};

    ${(props) =>
        props.allowToggle &&
        `
        justify-content: flex-start;
        cursor: pointer;
        margin-bottom: 10px;
        margin-top: 10px;
        transition: color 0.25s;
        svg {
            margin-right: 6px;
        }
    `};
`;

const CategoryPill = ({ name, large, allowToggle, isActive, onClick }) => (
    <Wrapper
        large={large}
        allowToggle={allowToggle}
        isActive={isActive}
        onClick={onClick}>
        {allowToggle && (
            <FlexWrapper align="center">
                {isActive ? <Remove /> : <Add />}
            </FlexWrapper>
        )}
        {name}
    </Wrapper>
);

CategoryPill.propTypes = {
    large: bool,
    allowToggle: bool,
    isActive: bool,
    onClick: func,
    ...categoryProps
};

CategoryPill.defaultProps = {
    large: false,
    allowToggle: false,
    isActive: true,
    onClick: undefined
};

export default CategoryPill;
