import React from 'react';
import { string } from 'prop-types';

import Email from 'TMComponents/Icons/Email';
import Facebook from 'TMComponents/Icons/Facebook';
import Twitter from 'TMComponents/Icons/Twitter';
import styled from 'styled-components';
import { H5 } from 'TMComponents/Typography';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const List = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    padding-inline-start: 0;
`;

const SocialSharing = ({ title, url }) => (
    <Wrapper>
        <H5>Share on:</H5>
        <List>
            <li>
                <a
                    aria-label="facebook"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Facebook />
                </a>
            </li>
            <li>
                <a
                    aria-label="twitter"
                    href={`http://www.twitter.com/share?url=${url}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Twitter />
                </a>
            </li>
            <li>
                <a
                    aria-label="email"
                    href={`mailto:?subject=Check out this article from Thinkmoney&body=${title} - ${url}`}
                    title="Share by Email">
                    <Email />
                </a>
            </li>
        </List>
    </Wrapper>
);

SocialSharing.propTypes = {
    title: string.isRequired,
    url: string.isRequired
};

export default SocialSharing;
