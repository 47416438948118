import React from 'react';
import styled from 'styled-components';

import FlexWrapper from 'TMComponents/FlexWrapper';
import Img from 'TMComponents/ImageDynamic';
import { Body, Bold } from 'TMComponents/Typography';

import authorProps from 'TMPropTypes/authorProps';

const Wrapper = styled(FlexWrapper)`
    margin: 10px 0;
`;

const AuthorImg = styled(Img)`
    border-radius: 50%;
`;

const NoPhoto = styled.div`
    background-color: ${(props) => props.theme.colors.greys.regular};
    border-radius: 50%;
    height: 40px;
    width: 40px;
`;

const AuthorName = styled(Body)`
    ${Bold}
    color: ${({ theme }) => theme.colorSystem.primary[1]};
    margin-bottom: 0;
    margin-left: 10px;
`;

const Author = ({ photo, name }) => (
    <Wrapper align="center">
        {photo ? <AuthorImg imageData={photo} /> : <NoPhoto />}
        <AuthorName>{name}</AuthorName>
    </Wrapper>
);

Author.propTypes = authorProps;

export default Author;
