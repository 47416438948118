/* SEARCH-TAG: page-template  */
import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import blogArticleProps from 'TMPropTypes/blogArticleProps';
import Img from 'TMComponents/ImageDynamic';
import Main from 'TMComponents/Layout/Main';
import HeadTags from 'TMComponents/HeadTags';
import NoIndex from 'TMComponents/NoIndex';
import NavLink from 'TMComponents/NavLink';
import { H1, Body, Bold } from 'TMComponents/Typography';
import ArticleDate from 'TMComponents/ArticleDate';
import Author from 'TMComponents/Author';
import CategoryPill from 'TMComponents/CategoryPill';
import ComponentMapper from 'TMComponents/ComponentMapper';
import SocialSharing from 'TMComponents/SocialSharing';
import Canonical from 'TMComponents/Canonical';
import { BreadcrumbsContext } from 'TMComponents/Breadcrumbs';
import { breakpoints } from 'TMConstants';
import { BannerContext } from 'TMComponents/Banner';
import { LayoutContext } from 'TMComponents/Layout';
import { getSrc } from 'gatsby-plugin-image';

const Article = styled.article`
    margin: 0 auto;
    max-width: 768px;
    padding: 0 16px;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        padding-bottom: 50px;
    }
`;

const Image = styled(Img)`
    border-radius: 8px;
    height: 250px;
    margin-bottom: 30px;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        height: 350px;
    }
`;

const ArticleInner = styled.div`
    margin-top: 40px;
`;

const BackText = styled(Body)`
    ${Bold}
    color: ${({ theme }) => theme.colorSystem.primary[4]};
    &:hover {
        text-decoration: underline;
    }
`;

const BlogArticle = ({ data: { datoCmsBlogArticle }, pageContext }) => {
    const {
        noIndex,
        slug,
        title,
        seoMetaTags,
        featuredImage,
        date,
        author,
        categories,
        content,
        id
    } = datoCmsBlogArticle;
    const { publishedAt } = pageContext;

    const isBannerAllowed = true;
    const isCrumbsAllowed = true;

    const { setIsNavHidden } = useContext(LayoutContext);
    const { setShowBanner } = useContext(BannerContext);
    const { setCrumbs } = useContext(BreadcrumbsContext);

    const crumbs = [
        { title: 'Blog', link: '/blog/' },
        { title, link: `/blog/${slug}/` }
    ];

    useEffect(() => {
        setIsNavHidden(false);
        setShowBanner(isBannerAllowed);
        setCrumbs({ show: isCrumbsAllowed, crumbList: crumbs });
    }, []);

    const schema = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': id
        },
        headline: title,
        image: [getSrc(featuredImage)],
        datePublished: publishedAt,
        author: {
            '@type': 'Person',
            name: author.name
        },
        publisher: {
            '@type': 'Organization',
            name: 'thinkmoney',
            logo: {
                '@type': 'ImageObject',
                url: 'https://www.datocms-assets.com/22599/1586343515-tmweb2020logostrapline.png?auto=compress%2Cformat&dpr=1&fm=png&w=180'
            }
        }
    };

    return (
        <>
            {noIndex ? <NoIndex /> : <Canonical href={`blog/${slug}`} />}
            <HeadTags tags={seoMetaTags.tags} schemaMarkup={schema} />
            <Main
                isBannerAllowed={isBannerAllowed}
                isCrumbsAllowed={isCrumbsAllowed}>
                <Article>
                    <Image imageData={featuredImage} />
                    <H1>{title}</H1>
                    <ArticleDate date={date} />
                    <Author {...author} />
                    {categories.map(({ name }) => (
                        <CategoryPill key={name} name={name} />
                    ))}
                    <ArticleInner>
                        {content.map((item) => (
                            <ComponentMapper key={item.id} {...item} />
                        ))}
                        <SocialSharing
                            title={title}
                            url={`https://thinkmoney.co.uk/blog/${slug}/`}
                        />
                        <NavLink href="/blog/">
                            <BackText>&lt; Back to articles</BackText>
                        </NavLink>
                    </ArticleInner>
                </Article>
            </Main>
        </>
    );
};

BlogArticle.propTypes = {
    data: shape({
        datoCmsBlogArticle: shape(blogArticleProps)
    }).isRequired,
    pageContext: shape({
        publishedAt: string
    }).isRequired
};

export const pageQuery = graphql`
    query ($slug: String!) {
        datoCmsBlogArticle(slug: { eq: $slug }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            id: originalId
            __typename
            noIndex
            slug
            title
            summary
            author {
                name
                photo {
                    gatsbyImageData(
                        width: 40
                        layout: FIXED
                        imgixParams: { auto: "compress" }
                    )
                }
            }
            date(formatString: "Do MMM YYYY")
            featuredImage {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            categories {
                __typename
                name
            }
            content {
                ...imageFields
                ...lottieFields
                ...tableFields
                ...textFields
                ...textStructuredFields
                ...videoFields
            }
        }
    }
`;

export default BlogArticle;
