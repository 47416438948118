import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Add = () => {
    const { colorSystem } = useContext(ThemeContext);
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Icon/Add">
                <rect
                    id="Rectangle 8"
                    x="0.875"
                    y="0.875"
                    width="26.25"
                    height="26.25"
                    rx="13.125"
                    stroke={colorSystem.primary[4]}
                    strokeWidth="1.75"
                />
                <path
                    id="+"
                    d="M12.9105 20.5545V15.609H7.96496C7.64996 15.609 7.36646 15.3255 7.36646 15.0105V14.1285C7.36646 13.8135 7.64996 13.53 7.96496 13.53H12.9105V8.55296C12.9105 8.23796 13.194 7.98596 13.509 7.98596H14.391C14.706 7.98596 14.9895 8.23796 14.9895 8.55296V13.53H19.9665C20.2815 13.53 20.5335 13.8135 20.5335 14.1285V15.0105C20.5335 15.3255 20.2815 15.609 19.9665 15.609H14.9895V20.5545C14.9895 20.8695 14.706 21.1215 14.391 21.1215H13.509C13.194 21.1215 12.9105 20.8695 12.9105 20.5545Z"
                    fill={colorSystem.primary[4]}
                />
            </g>
        </svg>
    );
};

export default Add;
