import { string, shape, arrayOf } from 'prop-types';
import imageProps from './imageProps';
import authorProps from './authorProps';
import categoryProps from './categoryProps';

export default {
    id: string,
    slug: string,
    title: string,
    summary: string,
    date: string,
    featuredImage: shape(imageProps),
    author: shape(authorProps),
    categories: arrayOf(shape(categoryProps))
};
